<template>
  <component
    :is="componentAttrs.is"
    :class="[
      $style.component,
      {
        [$style.componentBackgroundFill]: addBackground && content.backgroundRepeat === 'fill',
        [$style.componentBackgroundRepeat]: addBackground && content.backgroundRepeat === 'repeat',
        [$style.componentBackgroundFixed]: addBackground && content.backgroundRepeat === 'fix',
        [$style.componentRoundedBorder]: content.horizontalLayout || content.layoutTemplate === 'cms_card',
      }
    ]"
    :style="{
      backgroundColor: backgroundColorWrapper,
      ...imageStyle,
    }"
    v-bind="componentAttrs.attrs"
    v-on="componentAttrs.handlers"
  >
    <picture v-if="backgroundImages" :key="imageKey" :class="$style.backgroundImage">
      <template v-for="(img, idx) in backgroundImages" :key="idx">
        <template v-if="img?.url">
          <source
            v-for="url in [`${img.url}.webp`, img.url]"
            :key="url"
            :srcset="url"
            :type="url.endsWith('.webp') ? 'image/webp' : null"
            :media="!imageRemoved ? img.media : null"
          >
        </template>
      </template>
      <img
        ref="imgElement"
        src="data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs="
        :alt="altTag"
        loading="lazy"
        :class="$style.backgroundImageTag"
        data-background-image
        @load="onImageLoad"
        @error="onImageError"
      >
    </picture>
    <div v-if="isShadeVisible" :class="$style.shadeEffect" />
    <component
      :is="currentComponent?.component"
      :key="`${content.layoutTemplate}-${content.id}`"
      :content="content"
      :precalculated-image-on-parent="imageStyleOverwriteOnChild"
      :class="$style.currentComponent"
      :has-sidebar="hasSidebar"
      :has-section-title="hasSectionTitle"
      :analytics-banner-interaction="standardInteraction"
      :analytics-measurable="analyticsMeasurable"
      :row-id="rowId"
    />
  </component>
</template>

<script>
  import { mapState } from 'pinia';
  import AwStandardTypeConfig, {
    SCT_DIVIDED_TITLE_AND_TEXT,
    SCT_BAR_FOR_TEXT_AND_IMAGE,
    SCT_LARGE_BANNER,
    SCT_CMS_CARD,
    SCT_IMAGE_TEXT_LINK_COLUMNS,
  } from '~~/common/config/AwStandardTypeConfig';
  import { CONTENT_PAGE_ANALYTICS_STANDARD } from '~~/common/config/ContentPageTypeConfig';
  import { LvButton, LvImage } from '~~/common/components/loginet-vue-shop/index.mjs';
  import { colorModelToHex } from '~~/common/utils';
  import analyticsBannerInteractionMixin from '~~/common/mixins/analyticsBannerInteractionMixin.js';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { AnalyticsBannerInteraction } from '~~/common/utils/AnalyticsBannerInteraction';
  import { getBackgroundImages, onImageComplete, removeImageSourceTagOnError } from '~~/common/utils/imageHelper.js';

  export default {
    name: 'AwStandard',
    components: {
      LvButton,
      LvImage,
    },
    mixins: [analyticsBannerInteractionMixin],
    props: {
      content: {
        type: Object,
        required: true,
      },
      isAnalyticsRunByStandard: {
        type: Boolean,
        default: null,
      },
      addBackground: {
        type: Boolean,
        default: true,
      },
      rowId: {
        type: Number,
        default: null,
      },
      hasSidebar: {
        type: Boolean,
        default: false,
      },
      hasSectionTitle: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        AnalyticsBannerInteraction,
        imageData: null,
        imageRemoved: false,
      };
    },
    computed: {
      ...mapState(useUserInterfaceStore, {
        smMax: state => state.mediaQueries['mobile-max'],
        screenRange: state => state.mediaQueries,
      }),
      imageKey () {
        return `standard-${this.content.id}`;
      },
      contentMedia () {
        return this.content?.media || {};
      },
      largeBackgroundImage () {
        return this.contentMedia.largeBackgroundImage;
      },
      smallBackgroundImage () {
        return this.contentMedia.smallBackgroundImage;
      },
      backgroundImages () {
        const images = [
          {
            ...this.largeBackgroundImage,
            media: this.largeBackgroundImage && this.smallBackgroundImage ? `(min-width: ${this.$styleVariables.breakpointTablet})` : undefined,
          },
          this.smallBackgroundImage,
        ];

        return getBackgroundImages(images);
      },
      altTag () {
        return this.largeBackgroundImage?.alt || this.smallBackgroundImage?.alt || '';
      },
      currentComponent () {
        return AwStandardTypeConfig[this.content?.layoutTemplate] || this.$logger.error(`${this.content?.layoutTemplate} is not a valid AwStandard type.`);
      },
      componentAttrs () {
        return this.content.link
          ? {
            is: LvButton,
            attrs: {
              ...this.$hyperlinkComputeBindFromUrl(this.content.link),
              stretch: true,
              styling: 'reset',
            },
            handlers: this.genBannerInteractionClickEventHandlers({ title: this.content.title }),
          }
          : {
            is: 'div',
            attrs: null,
            handlers: {},
          }
        ;
      },
      isShadeVisible () {
        if (this.addBackground) {
          const {
            layoutTemplate,
            title,
            infoText,
            contentText,
          } = this.content || {};
          const showFade = layoutTemplate === SCT_LARGE_BANNER && (title || infoText || contentText);
          return showFade;
        } else {
          return false;
        }
      },
      backgroundColorWrapper () {
        const bgColor = (this.content?.backgroundColor?.hex && this.content?.backgroundColor?.alpha) ? colorModelToHex(this.content.backgroundColor) : 'transparent';
        return bgColor && ![SCT_DIVIDED_TITLE_AND_TEXT, SCT_BAR_FOR_TEXT_AND_IMAGE, SCT_LARGE_BANNER].includes(this.content?.layoutTemplate) ? bgColor : 'transparent';
      },
      isBgEffectApplied () {
        return this.imageData && this.addBackground;
      },
      imageStyle () {
        return (this.isBgEffectApplied
          ? {
            'background-image': `url("${this.imageData?.currentSrc}")`,
          }
          : null
        );
      },
      imageStyleOverwriteOnChild () {
        const isImageNotReady = (this.largeBackgroundImage || this.smallBackgroundImage) && !this.imageData;
        if (isImageNotReady) {
          return true;
        }
        return (this.imageData && !this.isBgEffectApplied && [SCT_CMS_CARD, SCT_IMAGE_TEXT_LINK_COLUMNS].includes(this.content?.layoutTemplate)
          ? `url("${this.imageData.currentSrc}")`
          : null
        );
      },
      standardInteraction () {
        const promotionComponentId = this.analyticsBannerInteraction?.promotionComponentId;
        return AnalyticsBannerInteraction.create({
          ...this.analyticsBannerInteraction,
          ...((!promotionComponentId || promotionComponentId === CONTENT_PAGE_ANALYTICS_STANDARD)
            ? {
              promotionComponentId: this.currentComponent?.analytics,
            }
            : null
          ),
        });
      },
    },
    mounted () {
      if (
        this.isAnalyticsRunByStandard === true || (
          this.isAnalyticsRunByStandard !== false &&
          this.currentComponent?.isAnalyticsRunByStandard !== false
        )
      ) {
        this.sendBannerViewInteraction(this.standardInteraction);
      }
      onImageComplete(this.$refs?.imgElement, { onImageLoad: this.onImageLoad, onImageError: this.onImageError });
    },
    methods: {
      onImageError ({ target }) {
        if (target) {
          removeImageSourceTagOnError(target);
          this.imageRemoved = true;
        }
      },
      onImageLoad ({ target }) {
        const { currentSrc } = target;
        if (!this.imageData || this.imageData?.currentSrc === 'data:image/png;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=') {
          this.imageData = { currentSrc };
        }
      },
    },
  };
</script>

<style module lang="scss" rel="stylesheet/scss">
.backgroundImage,
.shadeEffect,
.currentComponent {
  grid-column: 1 / -1;
  grid-row: 1 / -1;
}

.component {
  display: grid;
  height: 100%;
  text-align: initial;

  //!important is important to override lv-button/.link-simple:hover {background: transparent;} css rule
  background-position: top center !important;
  background-size: auto !important;
  object-position: top center !important;

  &BackgroundFill,
  &BackgroundFixed {
    background-repeat: no-repeat !important;
  }

  &BackgroundFill {
    background-size: cover !important;
  }

  &BackgroundRepeat {
    background-repeat: repeat !important;
  }

  &BackgroundFixed {
    background-position: center !important;
    background-size: cover !important;
    object-position: center !important;
  }

  &RoundedBorder {
    overflow: hidden;
    border-radius: 16px;
  }
}

.backgroundImageTag {
  opacity: 0.01;
}

.backgroundImage + .shadeEffect {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, .32), rgba(0, 0, 0, .32));
}

.currentComponent {
  width: 100%;
}
</style>
